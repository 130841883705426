import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from 'urql';
import Dialog from '@material-ui/core/Dialog';

import TicketDetails from './TicketDetails';
import TicketDetailsContext, { useTicketNumber, useTicketNumberUpdate } from './TicketDetailsContext'
import {readTicket, updateTicket} from '../../lib/GraphQLQueries'
import { useNotificationPush } from '../../features/Snackbar/SnackbarContext'

const TicketDetailsPopup = ({ }) => {
  const ticketNumber = useTicketNumber()
  const updateTicketNumber = useTicketNumberUpdate()
  const { pushSuccess ,pushError } = useNotificationPush()
  const [ticket] = useQuery({
    query: readTicket,
    pause: !ticketNumber,
    variables: {
      hashID: ticketNumber
    },
    requestPolicy: "network-only"
  })

  const [mutationStatus, checkinTicketMutation] = useMutation(updateTicket)

  const checkinTicket = () => {
    checkinTicketMutation({status: "completed", id: ticket.data.readOneTicket.id}).then(res => {
      if (!res.error) {
        pushSuccess('Check-In erfolgreich')
        updateTicketNumber('')
      }
    })
  }

  /**
   * close ticket detail view
   */
  const handleBack = () => {
    updateTicketNumber('')
  }

  if (ticket?.error && ticketNumber !== '') {
    handleBack()
    pushError(ticket.error.message.replace('[GraphQL] ', ''))
  }

  return (
    <Dialog
      fullWidth
      maxWidth={'sm'}
      // PaperProps={{ className: classes.popup }}
      open={ticket && !ticket.error && ticketNumber !== ''}
      onClose={handleBack}
      aria-labelledby="ticket-details"
    >
      <TicketDetails
        ticket={ticket?.data ? ticket.data.readOneTicket : {}}
        handleBack={handleBack}
        checkinTicket={checkinTicket}
      />
    </Dialog>
  );
}

TicketDetailsPopup.propTypes = {}

export default TicketDetailsPopup;
