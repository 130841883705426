import React, {useState} from 'react';
import {Box} from "@material-ui/core";


const Layout = ({ gameID, children, clearDatabase, setShowInfo, showInfo, setMenuDialogOpen, setTeamMenuDialogOpen }) => {

  return (
    <Box style={{
      width: '100%',
      maxWidth: '400px',
      margin: '0 auto',
      padding: 24
    }}>
      {children}
    </Box>
  );
};

Layout.propTypes = {}

export default Layout;
