import React, {useEffect, useState} from 'react';

import Layout from "./layout";
import TicketDetailsProvider from "./features/TicketDetails";
import SnackbarProvider from "./features/Snackbar";
import Scanner from "./features/Scanner";
import {Grid, Typography} from "@material-ui/core";

const App = ({}) => {

  return (
    <Layout>
      <SnackbarProvider>
        <TicketDetailsProvider>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant={'h4'} component={'h1'} gutterBottom>Ticket scannen:</Typography>
              <Scanner />
            </Grid>
          </Grid>
        </TicketDetailsProvider>
      </SnackbarProvider>
    </Layout>
  );
};

App.propTypes = {}

export default App;
