import React from 'react';
import Scanner from './Scanner';
import PropTypes from 'prop-types';

const ScannerProvider = ({}) => {
  return (
    <>
     <Scanner />
    </>
  );
}

ScannerProvider.propTypes = {}

export default ScannerProvider;
