import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  scanner: {
    width: '100%',
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(2),
    backgroundColor: 'black',
    overflow: 'hidden'
  },
}));
