import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const TicketNumberContext = createContext();
const TicketNumberUpdateContext = createContext();

export const useTicketNumber = () => useContext(TicketNumberContext)
export const useTicketNumberUpdate = () => useContext(TicketNumberUpdateContext)

const TicketDetailsContext = ({ children }) => {
  const [ticketNumber, setTicketNumber] = useState('')

  return (
    <TicketNumberContext.Provider value={ticketNumber}>
        <TicketNumberUpdateContext.Provider value={setTicketNumber}>
          {children}
        </TicketNumberUpdateContext.Provider>
    </TicketNumberContext.Provider>
  );
}

TicketDetailsContext.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default TicketDetailsContext
