import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import moment from 'moment';

const TicketDetails = ({ ticket, handleBack, checkinTicket }) => {
  const historyRef = useRef()
  const [historyOpen, setHistoryOpen] = useState(false)

  if (!ticket) {
    return (
      <Box style={{padding: 24, textAlign: "center"}}>
        <Typography><CloseIcon fontSize={"large"} color={"secondary"} /></Typography>
        <Typography>Das Ticket konnte nicht gefunden werden</Typography>
      </Box>
    );
  }

  const category = () => {
    switch (ticket.priceCategory) {
      case 'adult': return 'Erwachsene (4€)';
      case 'child': return 'Kind bis 14 Jahre (kostenlos)';
      case 'referee': return 'Schiedsrichter mit Nachweis (kostenlos)';
      default: return '';
    }
  }

  const status = () => {
    switch (ticket.status) {
      case 'reserved': return 'Reserviert';
      case 'paid': return 'Reserviert & Bezahlt';
      case 'completed': return 'Check-In erfolgreich';
      case 'canceled': return 'Storniert';
      default: return '';
    }
  }

  return (
    <Box style={{padding: 24}}>
      <Typography variant={"h5"} gutterBottom>Ticketdaten:</Typography>
      <Typography>{`${ticket?.firstname} ${ticket?.lastname}`}</Typography>
      <Typography>{`${ticket?.street}`}</Typography>
      <Typography gutterBottom>{`${ticket?.zip} ${ticket?.city}`}</Typography>
      <Typography gutterBottom>{`${category()}`}</Typography>
      <Typography><b>Status:</b>{` ${status()}`}</Typography>
      <br/>
      {!['completed', 'canceled'].includes(ticket.status) && (
        <Button onClick={checkinTicket} size={"large"} variant={"contained"} color={"primary"} fullWidth>Check-In</Button>
      )}
    </Box>
  );
}

TicketDetails.propTypes = {
  ticket: PropTypes.object,
  handleBack: PropTypes.func
}

export default TicketDetails;
