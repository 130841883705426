import React, { useEffect, useRef } from 'react';
import QrReader from 'react-qr-reader';
import { useTicketNumber, useTicketNumberUpdate } from '../TicketDetails/TicketDetailsContext';
import { useStyles } from './styles';

import { Base64 } from 'js-base64';

const Scanner = ({ }) => {
  const classes = useStyles()
  const ticketNumber = useTicketNumber()
  const updateTicketNumber = useTicketNumberUpdate()

  const handleError = err => {
    console.log(err)
  }
  const handleScan = data => {
    if (data && ticketNumber === '') {
      const explodedData = Base64.decode(data).split('#')
      if (explodedData.length === 2) {
        updateTicketNumber(explodedData[0])
      } else if (explodedData.length === 1) {
        updateTicketNumber(data)
      }
    }
  }

  return (
    <>
      <QrReader
        delay={1000}
        onError={handleError}
        onScan={handleScan}
        showViewFinder={false}
        className={classes.scanner}
      />
    </>
  );
}

Scanner.propTypes = {}

export default Scanner;
