import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles.css';
import {initDB} from "react-indexed-db";
import { Provider as URQLProvider } from 'urql';
import GraphQLClientSilverstripe from './lib/URQLClientSilverstripe';
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

// export const BUGSNAG_APP_TYPE = 'statistc'

// Bugsnag.start({
//   apiKey: 'dbae77aa5b73e6636fa082e512e2db19',
//   plugins: [new BugsnagPluginReact()],
//   appType: BUGSNAG_APP_TYPE,
//   releaseStage: process.env.NODE_ENV
// })
//
// initDB(DBConfig);

// const ErrorBoundary = Bugsnag.getPlugin('react')
//   .createErrorBoundary(React)

ReactDOM.render(
  <React.StrictMode>
    {/*<ErrorBoundary>*/}
      <URQLProvider value={GraphQLClientSilverstripe}>
        <App />
      </URQLProvider>
    {/*</ErrorBoundary>*/}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
