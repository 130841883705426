import gql from "graphql-tag";

export const readTicket = gql`
  query ReadOneTicket($hashID: String!){
    readOneTicket(filter: {hashID: {eq: $hashID}}){
      id
      hashID
      status
      priceCategory
      firstname
      lastname
      email
      street
      zip
      city
      created
    }
  }
`

export const updateTicket = gql`
  mutation UpdateTicket($status: String!, $id: ID!) {
    updateTicket(input: {status: $status, id: $id}) {
      id
      status
    }
  }
`
